import { MUIDataTableColumn } from 'mui-datatables';
import { ReasonSelect } from './ReasonSelect';
import { FormGroup, FormLabel, TextField } from '@mui/material';
import {AVAILABLE} from "./helpers";

export const columnsCapacityAssignment = (reasons?: any): MUIDataTableColumn[] => [
	{
		name: "site",
		label: "Site",
		options: {
			filter: false,
			sort: true,
			sortThirdClickReset: true,
			customFilterListOptions: { render: (v): string => `Site: ${v}` },
		}
	},
	{
		name: "id",
		label: "Route ID",
		options: {
			display: false,
			filter: true,
			sort: true,
			filterType: 'textField',
			customFilterListOptions: { render: (v): string => `Route: ${v}` },
		}
	},
	{
		name: "t5Shift",
		label: "T5 Shift #",
		options: {
			filter: true,
			sort: true,
			filterType: 'textField',
			customFilterListOptions: { render: (v): string => `T5 Shift #: ${v}` },
		}
	},
	{
		name: "code",
		label: "Full Route Name",
		options: {
			display: false,
			filter: true,
			sort: true,
			customFilterListOptions: { render: (code): string => `Full Route name include: ${code}` },
			filterType: 'textField',
		}
	},
	{
		name: "routeStart",
		label: "Route date",
		options: {
			filter: false,
			sort: true,
			filterList: [],
		}
	},
	{
		name: "lastStop",
		label: "Last stop",
		options: {
			filter: false,
			sort: false,
		}
	},
	{
		name: "lastStopDate",
		label: "Last stop date",
		options: {
			filter: false,
			sort: false,
		}
	},
	{
		name: "returnLocation",
		label: "Return Location",
		options: {
			filter: false,
			sort: false,
		}
	},
	{
		name: 'fromMiles',
		options: {
			filter: true,
			display: 'excluded',
			filterType: 'custom',
			customFilterListOptions: {
				render: v => {
					if (v[0]) {
						return `Min miles: ${v[0]}`;
					}
					return [];
				},
				update: (filterList, filterPos, index = 6) => {

					if (filterPos === 0) {
						filterList[index].splice(filterPos, 1, '');
					} else if (filterPos === 1) {
						filterList[index].splice(filterPos, 1);
					} else if (filterPos === -1) {
						filterList[index] = [];
					}

					return filterList;
				},
			},
			filterOptions: {
				names: [],
				logic(fromMiles, filters) {
					if (filters[0] && filters[1]) {
						return fromMiles < filters[0] || fromMiles > filters[1];
					} else if (filters[0]) {
						return fromMiles < filters[0];
					} else if (filters[1]) {
						return fromMiles > filters[1];
					}
					return false;
				},
				display: (filterList, onChange, index, column) => (
					<div>
						<FormLabel>From miles</FormLabel>
						<FormGroup row>
							<TextField
								type='number'
								value={filterList[index][0] || ''}
								onChange={event => {
									filterList[index][0] = event.target.value;
									onChange(filterList[index], index, column);
								}}
							/>
						</FormGroup>
					</div>
				),
			},
			print: false,
		},
	},
	{
		name: 'toMiles',
		options: {
			filter: true,
			filterType: 'custom',
			display: 'excluded',
			customFilterListOptions: {
				render: v => {
					if (v[1]) {
						return `Max miles: ${v[1]}`;
					}
					return [];
				},
				update: (filterList, filterPos, index) => {

					if (filterPos === 0) {
						filterList[index].splice(filterPos, 1, '');
					} else if (filterPos === 1) {
						filterList[index].splice(filterPos, 1);
					} else if (filterPos === -1) {
						filterList[index] = [];
					}

					return filterList;
				},
			},
			filterOptions: {
				names: [],
				logic(toMiles, filters) {
					if (filters[1]) {
						return toMiles > filters[1];
					}
					return false;
				},
				display: (filterList, onChange, index, column) => (
					<div>
						<FormLabel>To miles</FormLabel>
						<FormGroup row>
							<TextField
								type='number'
								value={filterList[index][1] || ''}
								onChange={event => {
									filterList[index][1] = event.target.value;
									onChange(filterList[index], index, column);
								}}
							/>
						</FormGroup>
					</div>
				),
			},
			print: false,
		},
	},
	{
		name: "name",
		label: "Route Name",
		options: {
			filter: true,
			sort: true,
			filterType: 'textField',
			customFilterListOptions: { render: (v): string => `Route name include: ${v}` },
		}
	},
	{
		name: "externalId",
		label: "Shift ID",
		options: {
			filter: true,
			sort: true,
			filterType: 'textField',
			customFilterListOptions: { render: (v): string => `Shift ID: ${v}` },
		}
	},
	{
		name: "returnMiles",
		label: "Return Miles",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "reason",
		label: "Reason",
		options: {
			filter: true,
			sort: true,
			customFilterListOptions: {
				render: (v): string => `Reason: ${v}`
			},
			filterOptions: {
				names: [...reasons?.map((i: any) => i.name), AVAILABLE],
			},
			filterType: 'checkbox',
			customBodyRender: (reason: any, routeId: any) => {
				return <ReasonSelect key={routeId.rowData[1]} reason={reason?.id} reasons={reasons} routeId={routeId} />
			}
		}
	},
];
